interface PathResult<T> {
  <TSubKey extends keyof T>(key: TSubKey): PathResult<T[TSubKey]>;
  pathLevels: string[];
  dotNotation: string;
  key: string;
}

/**
 * Class contains utility functions that extend native typescript type checking capabilities
 */
export class TS {
  public static property<T>() {
    function subpath<T, TKey extends keyof T>(parent: string[], key: TKey): PathResult<T[TKey]> {
      const newPath: any = [...parent, key];
      const x = (<TSubKey extends keyof T[TKey]>(subkey: TSubKey) =>
        subpath<T[TKey], TSubKey>(newPath, subkey)) as PathResult<T[TKey]>;
      x.pathLevels = newPath;
      x.dotNotation = x.pathLevels.join('.');
      x.key = x.pathLevels.join('.');
      return x;
    }

    return <TKey extends keyof T>(key: TKey) => subpath<T, TKey>([], key);
  }
}

/**
 * Construct a type with a set of property paths of object T
 * See https://stackoverflow.com/a/58436959/12517204
 *
 * Example:
 *
 * type NestedObjectType = {
 *     a: string;
 *     b: string;
 *     nest: {
 *         c: string;
 *     };
 *     otherNest: {
 *         d: string;
 *     };
 * };
 *
 * type NestedObjectPaths = Paths<NestedObjectType>; // "a" | "b" | "nest" | "otherNest" | "nest.c" | "otherNest.d"
 */
export type Paths<T, D extends number = 10> = [D] extends [never]
  ? never
  : T extends Record<string, any>
  ? {
      [K in keyof T]-?: K extends string | number ? `${K}` | Join<K, Paths<T[K], Prev[D]>> : never;
    }[keyof T]
  : '';

/**
 * Construct a type with a set of property paths of object T
 * See https://stackoverflow.com/a/58436959/12517204
 *
 * Example:
 *
 * type NestedObjectType = {
 *     a: string;
 *     b: string;
 *     nest: {
 *         c: string;
 *     };
 *     otherNest: {
 *         d: string;
 *     };
 * };
 *
 * type NestedObjectLeaves = Leaves<NestedObjectType>; // "a" | "b" | "nest.c" | "otherNest.d"
 */
export type Leaves<T, D extends number = 10> = [D] extends [never]
  ? never
  : T extends Record<string, any>
  ? { [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D]>> }[keyof T]
  : '';

type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]];

type Join<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}${'' extends P ? '' : '.'}${P}`
    : never
  : never;

export type RequiredField<T, K extends keyof T> = Partial<T> & Required<Pick<T, K>>;

/**
 * Creates type for a class to pass it as an argument to a function
 *
 * Example of usage:
 *
 * class User {
 *  name: string;
 * }
 *
 * class SchemaFactory {
 *  createForClass(target: ClassType<User>) {
 *    // some code here using target
 *  }
 * }
 *
 * const model = SchemaFactory.createForClass(User);
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export interface ClassType<T = any> extends Function {
  new (...args: any[]): T;
}
